<!-- eslint-disabled -->
<template>
  <div class="Register">
    <div id="details" class="flex py-8">
      <div class="w-1/5 justify-center flex items-center">
        <a href="/">
          <Logo class="logo" />
        </a>
      </div>
      <div class="w-3/5 flex items-center justify-center progression">
        <div class="block w-full">
          <h1>
            {{ $t("register.title") }}
          </h1>
        </div>
      </div>
    </div>

    <form id="content" class="flex py-20 overflow-y-auto" @submit.prevent="send">
      <input value="Quiz" name="form" type="hidden">
      <div class="w-1/5" />
      <div class="w-3/5">
        <div v-if="error" class="error pb-4">
          {{ error }}
        </div>
        <div>
          <div class="flex">
            <label for="firstname" class="w-1/4">{{ $t("register.firstname") }}</label>
            <input
              id="firstname"
              v-model="$store.state.form.firstname"
              name="first_name"
              required
            >
          </div>
          <div class="flex">
            <label for="lastname" class="w-1/4">{{ $t("register.lastname") }}</label>
            <input
              id="lastname"
              v-model="$store.state.form.lastname"
              name="last_name"
              required
            >
          </div>
          <div class="flex">
            <label for="email" class="w-1/4">{{ $t("register.email") }}</label>
            <input
              id="email"
              v-model="$store.state.form.email"
              name="email"
              type="email"
              required
            >
          </div>
        </div>

        <div class="my-12 agb">
          <h2>{{ $t("register.terms_title") }}</h2>
          <p v-html="$t('register.terms')" />
        </div>

        <div>
          <button class="button float-right">
            {{ $t("register.send") }}
          </button>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
import Logo from '@/assets/images/UPD-Logo-Colour.svg';

export default {
  components: { Logo },
  data: () => ({ error: '' }),
  methods: {
    async send() {
      const response = await this.$store.dispatch('register');
      console.log(response);
      if (response.data.type === 'error') {
        this.error = response.data.message;
        console.log(this.error);
        return;
      }
      this.$router.push({ path: '/thanks' });
    },
  },
};
</script>

<style lang="scss" scoped>
#details {
  background-color: var(--brightgray);
  min-height: 13%;
}
.logo {
  max-height: 70px;
  width: 100%;
}

label {
  font-size: 25px;
  line-height: 92px;
  color: black;
  flex-shrink: 0;
  font-weight: 600;
  font-family: "Open Sans";
}
input {
  font-size: 25px;
  line-height: 49px;
  background: rgba(170, 170, 170, 0.2);
  border-radius: 10px;
  color: black;
  font-weight: normal;
  padding: 30px;
  font-weight: 600;
  font-family: "Open Sans";
}

.error {
  color: red;
  font-weight: bold;
}

.agb {
  h2 {
    color: #000;
    font: normal normal 600 36px/49px Open Sans;
    margin-bottom: 1rem;
  }
  p {
    font-size: .8rem;
  }
  font: normal normal normal 18px/24px Open Sans;
  color: #000;
}
</style>
